<div *ngIf="locationObs$ | async as location; else loadingOrErrorForLocation">
    <div class="d-flex justify-content-between align-items-center">
        <h3 class="m-0">
            {{ ('scan.locationDetails.title' | translate).replace('{locationName}', location.name) }}
        </h3>
        <div *ngIf="locationReservationObs$ | async as reservations;">
            <button class="button button-primary" (click)="scannerModal.open()"  *ngIf="reservations.length > 0">
                {{'scan.phone' | translate}}
            </button>
        </div>
    </div>
    <div *ngIf="locationReservationObs$ | async as reservations; else loadingOrError">
        <div *ngIf="reservations.length > 0; else noUsers">
            <app-message>
                {{"scan.warn-unattended" | translate}}
                {{"scan.handheld-hint" | translate}}
            </app-message>
            <app-location-reservations 
                [currentTimeSlot]="location.currentTimeslot" 
                [locationReservations]="reservations" 
                [lastScanned]="scannedReservation" 
                [isManagement]="false">
            </app-location-reservations>
        </div>
    </div>
</div>

<app-modal (onModalClose)="scannerComponent.disableScanner()" (onModalOpen)="scannerComponent.enableScanner()" #scannerModal>
    <div modal-header>
        <h2>{{ 'scan.phone' | translate }}</h2>
    </div>
    <div *ngIf="locationReservationObs$ | async as reservations" modal-body>
        <app-message *ngIf="!selectedReservation && !scannedReservation">
            {{'scan.mobile-hint' | translate}}
        </app-message>
        <app-message *ngIf="!scanningError && scannedReservation" type="status" class="w-100">
            {{'scan.successText' | translate}}
        </app-message>
        <app-scanner
            [class.hide]="selectedReservation || scanningError" 
            (scanSuccess)="scanUser(reservations, $event)">
        </app-scanner>
    </div>
    <div modal-footer>
        <app-message *ngIf="selectedReservation" class="w-100">
            {{'scan.confirmText' | translate: { 
                name: selectedReservation.user.firstName + ' ' + selectedReservation.user.lastName,
                id: selectedReservation.user.userId
            } }}
            <div class="d-flex gap-3 mt-3">
                <button class="button button-primary" (click)="confirmReservation()">{{'scan.confirmButton' | translate}}</button>
                <button class="button button-secondary" (click)="selectedReservation = null">{{'scan.cancelButton' | translate}}</button>
            </div>
        </app-message>
        <app-message *ngIf="scanningError" type="error" class="w-100">
            <span>{{'scan.noReservation' | translate}}</span>
            <button class="button button-primary mt-3" (click)="resetScanningError()">{{'scan.confirmButton' | translate}}</button>
        </app-message>
    </div>
</app-modal>

<ng-template #loadingOrError>
    <div *ngIf="hasLoadingError; else loading" class="messages messages--error">
        {{'scan.locationDetails.errorMessageOnLoadingUsers' | translate}}
    </div>
    <ng-template #loading>
        <div class="messages messages--info">
            {{'general.waitingForServer' | translate}}
        </div>
    </ng-template>
</ng-template>

<ng-template #loadingOrErrorForLocation>
    <div *ngIf="isLoading; else loading" class="messages messages--error">
        {{'scan.locationDetails.errorMessageOnLoadingLocation' | translate}}
    </div>
    <ng-template #loading>
        <div class="messages messages--info">
            {{'general.waitingForServer' | translate}}
        </div>
    </ng-template>
</ng-template>

<ng-template #noUsers>
    <div class="messages messages--warning">
        <i class="icon-cross" aria-hidden="true"></i>
        {{'scan.locationDetails.noUsersToScanWarning' | translate}}
    </div>
</ng-template>
