<ng-container *ngIf="$categories | async as categories; else loading">
    <div id="faq">
        <div id="faq-header" class="d-flex align-items-center justify-content-between px-4 py-2">
            <span id="faq-header-title" class="fs-6">
                {{ 'faq.header.title' | translate }}
            </span>
            <div [routerLink]="'/faq'" id="faq-header-search" class="d-flex align-items-center gap-2">
                <span class="icon-search fs-5"></span> {{ 'faq.header.search' | translate }}
            </div>
        </div>
        <div id="faq-content" class="row">
            <div class="col-12 col-lg-3">
                <app-faq-sidebar [categories]="categories" [locale]="locale"></app-faq-sidebar>
            </div>
            <div class="col-12 col-lg-9">
                <!-- Content -->
                <div id="faq-content-container">
                    <router-outlet></router-outlet>
                </div>

                <!-- Contact footer -->
                 <app-contact
                    [title]="'faq.contact.title' | translate"
                    [content]="'faq.contact.subtitle' | translate">
                 </app-contact>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
