<!-- Form with faq about location -->
<ng-container>
    <form [formGroup]="formGroup">
        <!-- Location name -->
        <div class="row">
            <div class="col">
                <label class="control-label" for="name">
                    {{'management.locationDetails.form.name' | translate}}
                    <input class="form-control" id="name" type="text" formControlName="name">
                </label>
            </div>
        </div>

        <!-- Authority -->
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label class="control-label" for="authority">
                        {{'management.locationDetails.form.authority' | translate}}
                        <select class="form-control" id="authority" formControlName="authority">
                            <option *ngFor="let authority of authorities" [value]="authority.authorityId">
                                {{authority.authorityName + ' (' + authority.description + ')'}}
                            </option>
                        </select>
                    </label>
                </div>
            </div>
        </div>

        <!-- Building -->
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label class="control-label" for="building">
                        {{'management.locationDetails.form.building' | translate}}
                        <select class="form-control" id="building" formControlName="building">
                            <option *ngFor="let building of buildings" [value]="building.buildingId">
                                {{building.name + ' (' + building.address + ')'}}
                            </option>
                        </select>
                    </label>
                </div>
            </div>
        </div>

        <!-- Number of seats -->
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label class="control-label" for="numberOfSeats">
                        {{'management.locationDetails.form.numberOfSeats' | translate}}
                        <input class="form-control" id="numberOfSeats" type="number" formControlName="numberOfSeats">
                    </label>
                </div>
            </div>
        </div>

        <!-- Image URL -->
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label class="control-label" for="imageUrl">
                        {{'management.locationDetails.form.imageUrl' | translate}}
                        <input class="form-control" id="imageUrl" type="text" formControlName="imageUrl">
                    </label>
                </div>
            </div>
        </div>

        <!-- Uses penalty points -->
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <app-checkbox
                        [id]="'usesPenaltyPoints'"
                        [label]="'management.locationDetails.form.usesPenaltyPoints' | translate"
                        [formControlName]="'usesPenaltyPoints'">
                    </app-checkbox>
                </div>
            </div>
        </div>

        <!-- Should the location be hidden in the overview? -->
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <app-checkbox
                        [id]="'hidden'"
                        [label]="'management.locationDetails.form.isHidden' | translate"
                        [formControlName]="'hidden'">
                    </app-checkbox>
                </div>
            </div>
        </div>

        <!-- Buttons to edit/cancel/persist location -->
        <div class="row">
            <div class="col">
                <div class="w-100 d-flex gap-3 flex-column flex-md-row justify-content-between">
                    <div>
                        <!-- Button to make form editable -->
                        <button type="button" class="button button-primary btn-space"
                                [disabled]="disableEditLocationButton"
                                (click)="editLocationDetailsButtonClick()">
                            {{'management.locationDetails.form.edit' | translate}}
                        </button>
                    </div>

                    <div class="d-flex gap-3">
                        <!-- Button to persist changes -->
                        <button type="button" class="button button-primary"
                                [disabled]="disablePersistLocationButton"
                                (click)="persistLocationDetailsButtonClick()">
                            {{'management.locationDetails.form.persist' | translate}}
                        </button>

                        <!-- Button to cancel changes -->
                        <button type="button" class="button button-secondary"
                                [disabled]="disableCancelLocationButton"
                                (click)="cancelLocationDetailsButtonClick()">
                            {{'management.locationDetails.form.cancel' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="messages messages--info" *ngIf="successUpdatingLocation === null">
                <i class="icon-info"></i>
                {{'management.locationDetails.form.loading' | translate}}
            </div>

            <div class="messages messages--success" *ngIf="successUpdatingLocation === true">
                <i class="icon-checkmark"></i>
                {{'management.locationDetails.form.success' | translate}}
            </div>

            <div class="messages messages--error" *ngIf="successUpdatingLocation === false">
                <i class="icon-cross"></i>
                {{'management.locationDetails.form.error' | translate}}
            </div>
        </div>
    </form>
</ng-container>
