<ng-container *ngIf="{
    location: locationObs$ | async,
    authorities: authoritiesObs$ | async,
    buildings: buildingsObs$ | async,
    timeslots: timeslotsObs$ | async
} as context">
    <ng-container *ngIf="context.location as location; else loading">
        <div class="panel">
            <h2>
                {{('management.locationDetails.title' | translate).replace('{locationName}', location.name)}}
            </h2>
            
            <hr>

            <app-details-form
                [location]="location"
                [authorities]="context.authorities"
                [buildings]="context.buildings">
            </app-details-form>

            <!-- Calendar with overview of opening days -->
            <h2>
                {{'management.locationDetails.calendar.title' | translate}}
            </h2>

            <hr/>

            <app-location-calendar
                [location]="location"
                [timeslots]="context.timeslots"
                (updatedTimeslots)="refresh$.next()">
            </app-location-calendar>

            <!-- Tags of the location -->
            <h2>
                {{'management.locationDetails.tags.title' | translate}}
            </h2>

            <hr/>

            <app-location-tags-management
                [location]="locationObs$">
            </app-location-tags-management>

            <!-- Description of the location -->
            <h2>
                {{'management.locationDetails.description.title' | translate}}
            </h2>

            <hr/>

            <app-location-description
                [location]="locationObs$">
            </app-location-description>
        </div>
    </ng-container>

    <ng-template #loading>
        <div class="messages messages--info">
            <i class="icon-info"></i>
            {{'management.general.loading' | translate}}
        </div>
    </ng-template>
</ng-container>
