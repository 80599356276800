<section class="messages messages--error" *ngIf="showStagingWarning">
    <div markdown *ngIf="currentLanguage() | async as currlan" src="assets/md/staging-warning.{{currlan}}.md">>
    </div>
</section>

<div *ngIf="locations !== undefined && successOnRetrievingLocations === true">
    <div *ngIf="locations.length > 0; else noLocations">
        <div class="dashboard-item-container d-flex flex-wrap gap-3" *ngIf="filteredLocations && filteredLocations.length > 0; else noMatchesFromFilter">
            <app-dashboard-item style="width: calc(33% - 32px)" *ngFor="let location of filteredLocations" [location]="location"
                [nextReservableFrom]="locationNextReservableFroms.get(location.name)">
            </app-dashboard-item>
        </div>
    </div>
</div>

<!-- Feedback to the user when the locations are loading/can't be retrieved -->
<div class="container-fluid">
    <div class="alert alert-info" *ngIf="successOnRetrievingLocations === null">
        {{'general.waitingForServer' | translate}}
    </div>

    <div class="alert alert-error" *ngIf="successOnRetrievingLocations === false">
        {{'dashboard.error' | translate}}
    </div>
</div>

<ng-template #noMatchesFromFilter>
    <div class="alert alert-warning">
        {{'dashboard.filter.noMatchesFromFilter' | translate}}
    </div>
</ng-template>

<ng-template #noLocations>
    <div class="alert alert-warning">
        {{'dashboard.noLocations' | translate}}
    </div>
</ng-template>

<!-- Label to remove warning, not set -->
<label for="search"></label>

<section class="highlight highlight--top banner">
    <div class="highlight__inner" markdown *ngIf="currentLanguage() | async as currlan"
        src="assets/md/banner.{{currlan}}.md">>
    </div>
</section>