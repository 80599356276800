<!-- Modal header -->
<app-modal #modal>
    <h2 modal-header *ngIf="!isUpdating()">
        {{'management.locationDetails.calendar.addModalView.title' | translate}}
    </h2>

    <h2 modal-header *ngIf="isUpdating()">
        {{'management.locationDetails.calendar.updateModalView.title' | translate}}
    </h2>

    <div modal-body>
        <ng-container *ngIf="lockReservable()">
            <div class="messages messages--info" role="alert" aria-atomic="true">
                <i class="icon icon-info"></i>
                <p>{{ ('management.locationDetails.calendar.addModalView.thresholdMessage' | translate).replace('{seatThreshold}', seatThreshold) }}</p>
            </div>
        </ng-container>
        <form [formGroup]="timeslotFormGroup">
            <!-- Starts at -->
            <div class="row">
                <div class="col">
                    <label class="control-label" for="startsAt">
                        {{'management.locationDetails.calendar.form.date' | translate}}
                        <app-moment-date 
                            id="startsAt"
                            type="date"
                            formControlName="timeslotDate"
                            [min]="getMinStartDate()">
                        </app-moment-date>
                    </label>
                </div>
            </div>

            <!-- Opening time -->
            <div class="row">
                <div class="col">
                    <label class="control-label" for="openingTime">
                        {{'management.locationDetails.calendar.form.openingTime' | translate}}
                        <app-moment-date 
                            type="time"
                            id="openingHour"
                            formControlName="openingHour">
                        </app-moment-date>
                    </label>
                </div>
            </div>

            <!-- Closing time -->
            <div class="row">
                <div class="col">
                    <label class="control-label" for="closingTime">
                        {{'management.locationDetails.calendar.form.closingTime' | translate}}
                        <app-moment-date
                            type="time"
                            id="closingTime"
                            formControlName="closingHour"
                            [min]="getMinClosingTime()">
                        </app-moment-date>
                    </label>
                </div>
            </div>

            <!-- Repeat -->
            <div class="row">
                <div class="col">
                    <app-checkbox
                        formControlName="repeatable"
                        [id]="'repeatable'"
                        [label]="'management.locationDetails.calendar.form.repeatable' | translate">
                    </app-checkbox>
                </div>
            </div>

            <!-- Reservable -->
            <div class="row">
                <div class="col">
                    <div class="d-flex align-items-center gap-2">
                        <app-checkbox
                            formControlName="reservable"
                            [id]="'reservable'"
                            [label]="'management.locationDetails.calendar.form.reservable' | translate">
                        </app-checkbox>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="timeslotFormGroup.get('reservable').value">
                <!-- Reservable from -->
                <div class="row">
                    <div class="col">
                        <label class="control-label" for="reservableFrom">
                            {{'management.locationDetails.calendar.form.reservableFrom' | translate}}
                            <app-moment-date 
                                id="reservableFrom"
                                formControlName="reservableFrom"
                                type="datetime"
                                [min]="getMinReservableFrom()"
                                [max]="getMaxReservableFrom()">
                            </app-moment-date>
                        </label>
                    </div>
                </div>

                <!-- Seat count -->
                <div class="row">
                    <div class="col">
                        <label class="control-label" for="seatCount">
                            {{'management.locationDetails.calendar.form.numberOfSeats' | translate}}
                            <input
                                type="number"
                                name="seatCount"
                                formControlName="seatCount"
                                [min]="getMinSeatCount()"/>
                        </label>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>

    <div modal-footer>
        <button class="button button-primary" (click)="confirm()" [disabled]="!timeslotInstance.isValid()" *ngIf="!isUpdating()">
            {{'general.buttons.add' | translate}}
        </button>

        <button class="button button-primary" (click)="confirm()" [disabled]="!timeslotInstance.isValid()" *ngIf="isUpdating()">
            {{'general.buttons.update' | translate}}
        </button>

        <button type="button" class="button button-secondary" (click)="modal.close()">
            {{'general.buttons.cancel' | translate}}
        </button>
    </div>

    <div modal-message-footer>
        <div *ngIf="displayErrorTime">
            <div class="messages messages--warning">
                <i class="icon-exclamation"></i>
                {{'management.locationDetails.calendar.form.openingClosingHourError' | translate}}
            </div>
        </div>
        <div *ngIf="displayErrorSeats">
            <div class="messages messages--warning">
                <i class="icon-exclamation"></i>
                {{('management.locationDetails.calendar.form.seatsError' | translate).replace('{amountOfReservations}', timeslotInstance.amountOfReservations) }}
            </div>
        </div>
    </div>
</app-modal>

