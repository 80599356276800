<app-message type="warning" *ngIf="isEnabled && !hasPermission">
    {{ 'scan.noPermission' | translate }}
</app-message>
<zxing-scanner id="scanner"
    *ngIf="isEnabled"
    [torch]="isTorchEnabled"
    [device]="deviceCurrent"
    [formats]="formatsEnabled"
    [tryHarder]="isTryHarder"
    [enable]="isEnabled"
    (permissionResponse)="onHasPermission($event)"
    (deviceChange)="onDeviceChange($event)"
    (scanSuccess)="onCodeResult($event)"
    (camerasFound)="onCamerasFound($event)"
    (torchCompatible)="onTorchCompatible($event)">
</zxing-scanner>